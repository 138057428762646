import React, { useState, useEffect } from "react";
import { Link, withPrefix } from "gatsby";
import { Container, Navbar, Nav, NavDropdown, NavItem, Button } from "react-bootstrap";
import ModalCita from "./widgets/modalContactanos";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';

import Image from "./widgets/basico/image";
import waIcon from "../images/icons/whats_icon.png";
import mailIcon from "../images/icons/correo_icon.png";
import telIcon from "../images/icons/tel_icon.png";

export default function Header ({selectedMenuItem, navActivo, parameters, marca}) {

    const [cita, setCita] = useState(false);
    const [ colorHeader, setColorHeader ] = useState();
    const [ imgHeader, setImageHeader ] = useState();
    const [ empresa, setEmpresa ] = useState();

    const [ contacto, setContacto ] = useState();

    const [ contactaIcon, setContactaIcon ] = useState({
        telefono: "",
        whatsapp: "",
    });

    const [ contactaIconNissan, setContactaIconNissan ] = useState({
        telefono: "",
        whatsapp: "",
    });
    
    const tallerOptions = ["taller-servicio", "taller-colision", "refacciones"];
    const weOptions = ["quienes-somos", "horario", "blog"];
    const promoOptions = ["promo-cars", "promo-accesorios", "promo-taller"];
    const [ windowSplit, setWindowSplit ] = useState();

    const NavDropItem = ({ children, selected, title, to }) => {
        return (
            <NavDropdown.Item
                as={Link}
                to={withPrefix(to)}
                className={selected ? "selected text-primary" : "text-header-inactivo"}
            >
                {title || null}
                {children || null}
            </NavDropdown.Item>
            );
    };
    //console.log("Estamos en el HEADER, los parametros: ", parameters);
    useEffect(() => {
    
        let splitUrl = window.location.pathname.split("/");
        setWindowSplit(splitUrl[1]);
        //console.log("SplitUtl, ", splitUrl[1]);
        
        if(navActivo === "Principal"){
            setEmpresa("principal");
            setColorHeader("barraMenuNissan");
            setImageHeader("nissan_header_logo.png");
            setContactaIconNissan({
                ...contactaIconNissan,
                whatsapp: "boton_nissan_header_whatsapp.png",
                telefono: "boton_nissan_header_telefono.png"
            })
        }else if(splitUrl[1] === "nissan" || marca === "nissan") {
            setEmpresa("nissan");
            setColorHeader("barraMenuNissan");
            setImageHeader("nissan_header_logo.png");
            setContactaIconNissan({
                ...contactaIconNissan,
                whatsapp: "boton_nissan_header_whatsapp.png",
                telefono: "boton_nissan_header_telefono.png"
            })
        }
        else {
            setEmpresa("principal");
            setColorHeader("barraMenuNissan");
            setImageHeader("nissan_header_logo.png");
            setContactaIcon({
                ...contactaIconNissan,
                whatsapp: "boton_nissan_header_whatsapp.png",
                telefono: "boton_nissan_header_telefono.png"
            })
        }

    }, [])
    //console.log("Tengo la empresa: ", marca);
    //Detector parametros url que tendrá durante la página

    let tagManagerArgs = {
        gtmId: 'GTM-W7HQJGC6', //Este ID se obtiene en el admin de GTM
        dataLayer: {
            'event': contacto,
        }
    }
 
    useEffect (() => {

        if (tagManagerArgs.dataLayer.event != undefined){
            console.log(tagManagerArgs.dataLayer.event);
            TagManager.initialize(tagManagerArgs);
        }
    }, [tagManagerArgs]);

    function sendData(num){ 
        setContacto(num);
    }

    return(
        
        <React.Fragment>


{/*         <noscript>
            <iframe
            src="https://www.googletagmanager.com/gtag/js?id=G-Z8WVEC2V1Z"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
            ></iframe>
        </noscript> */}
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W7HQJGC6" height="0" width="0" style={{"display": "none", "visibility": "hidden"}}></iframe></noscript>
        <noscript> <img height="1" width="1" src="https://www.facebook.com/tr?id=1469889453605563&ev=PageView&noscript=1"/> </noscript>
        <Helmet>
            <meta name="facebook-domain-verification" content="spzhwiyaarvxat6p7tc2r0qu4lexbw" />
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z8WVEC2V1Z"></script>

        </Helmet>
        
            <Helmet
                script={[
                    {
                        innerHTML: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-Z8WVEC2V1Z');
                        `,
                    },
                ]}
            ></Helmet>
            <Helmet
                    script={[
                        {
                            innerHTML: `
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window,document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '1469889453605563');
                            fbq('track', 'PageView');
                            `,
                        },
                    ]}
            ></Helmet>
            <Helmet
                    script={[
                        {
                            innerHTML: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-W7HQJGC6');
                            `,
                        },
                    ]}
            ></Helmet>
            <Navbar expand="lg" fixed="top" variant="dark" id={navActivo === "Principal" ? "barritaDebajo" : `${colorHeader}`}>
                <Container style={{height: '100%'}}>
                    <Navbar.Brand>
                        <Link to={parameters ? "/" + parameters : "/"} id="headerImageBrand">
                            <Image
                                className="d-md-block d-none"
                                fileName={imgHeader}
                                alt="banner"
                                imgStyles={{position: 'absolute', objectFit: 'contain', width: '100%'}}
                            />
                        </Link>
                    </Navbar.Brand>
{/*                     <div className="d-xl-none d-lg-none d-md-flex d-sm-flex contactos-header" style={{display: 'flex', zIndex: '90'}}>
                        <a href="mailto:informes@gruporivero.com" className="p-0 aContact" style={{width: '40%',  borderRadius: '30px'}} >
                        <img
                                className="d-xl-none d-lg-none d-md-block d-sm-block"
                                alt="banner"
                                src={mailIcon}
                                imgStyles={{position: 'absolute', height:'1em!important'}}
                            />
                        </a>
                        <a href="tel:8188626262" className="p-0 aContact" style={{width: '40%',  borderRadius: '30px'}} >
                        <img
                                className="d-xl-none d-lg-none d-md-block d-sm-block"
                                alt="banner"
                                src={telIcon}
                                imgStyles={{position: 'absolute', height:'1em!important'}}
                            />
                        </a>
                        <a href="https://wa.me/+5218120010000" className="p-0 aContact" style={{width: '40%',  borderRadius: '30px'}} >
                        <img
                                className="d-xl-none d-lg-none d-md-block d-sm-block"
                                alt="banner"
                                src={waIcon}
                                imgStyles={{position: 'absolute', height:'1em!important'}}
                            />
                        </a>
                    </div> */}

{/*                     <div className="d-xl-none d-lg-none d-md-block d-sm-block">

                    </div>

                    <div className="d-xl-none d-lg-none d-md-block d-sm-block">

                    </div> */}

                    <Navbar.Toggle aria-controls="basic-navbar-nav" variant="white"/> 
                    <Navbar.Collapse className="text-right">
                        <Navbar>
                            <Nav className="small-menu-text">

                                {empresa === "nissan" ? (
                                    <NavDropItem
                                        to={parameters ?  "/nissan/catalogo/nuevos" + parameters  :   "/nissan/catalogo/nuevos"}
                                        title="Autos Nuevos"
                                        id="Catalogo Nuevos Header"
                                    />
                                ) : (<NavDropItem
                                    to={parameters ?  "/nissan/catalogo/nuevos" + parameters  :   "/nissan/catalogo/nuevos"}
                                    title="Autos Nuevos"
                                    id="Catalogo Nuevos Header"
                                />
                                )}

                                <NavItem>
                                    <hr />
                                </NavItem>

                                <Nav >
                                    <NavDropItem
                                        className="d-md-block text-white"
                                        to={empresa=='nissan' || navActivo=='Principal'?
                                            parameters ? "catalogo/seminuevos/" + parameters : "catalogo/seminuevos/"
                                        /* :
                                        empresa=='chevrolet' || navActivo=='Principal'?
                                            parameters ? "catalogo/seminuevos-chevrolet/" + parameters : "catalogo/seminuevos-chevrolet/" */
                                        :
                                            parameters ? "catalogo/seminuevos/" + parameters : "catalogo/seminuevos/"
                                        }
                                        id="Seminuevos Header"
                                        selected={selectedMenuItem === "seminuevos"}
                                        title="Seminuevos"
                                    />
                                </Nav>

                                <NavDropdown title="Servicios de Taller" variant="dark" 
                                className={ tallerOptions.indexOf(selectedMenuItem) > -1 ? "text-primary selected" : "NavDropDownPadding"}>
                                    <NavDropItem
                                        to={
                                            empresa == 'nissan' ? 
                                                parameters ?  "/taller-de-servicio" + parameters  :   "/taller-de-servicio" 
                                           /*  :
                                            empresa == 'chevrolet' || windowSplit === 'nuevos'? 
                                                parameters ? "/chevrolet/taller-de-servicio" + parameters : "/chevrolet/taller-de-servicio" */
                                            : 
                                                parameters ? "/taller-de-servicio" + parameters : "/taller-de-servicio"
                                        }
                                        title="Taller de Servicio"
                                        id="Taller de Servicio Header"
                                    />
                                    <NavDropItem
                                        to={
                                            empresa == 'nissan'?
                                                parameters ? "/taller-de-colision" + parameters : "/taller-de-colision"
                                            /* :
                                            empresa == 'chevrolet' || windowSplit === 'nuevos' ? 
                                                parameters ? "/chevrolet/taller-de-colision/" + parameters : "/chevrolet/taller-de-colision/" */
                                                : 
                                                parameters ? "/taller-de-colision" + parameters : "/taller-de-colision"
                                        }
                                        title="Taller de Colisión"
                                        id="Taller de Colision Header"
                                    />
                                    {/* {empresa !== 'nissan' && (
                                            <NavDropItem
                                                to={
                                                    empresa === 'chevrolet' || windowSplit === 'nuevos' ? 
                                                        parameters ? "/chevrolet/refacciones" + parameters : "/chevrolet/refacciones"
                                                        : 
                                                        parameters ? "/servicios-refacciones/" + parameters : "/servicios-refacciones/"
                                                }
                                                title="Refacciones"
                                                id="Refacciones Header"
                                            />
                                        )} */}
                                    {/* <NavDropItem
                                        to={
                                            empresa == 'nissan'?
                                            parameters
                                              ? "refacciones-nissan" + parameters
                                              : "refacciones-nissan"
                                              :
                                            parameters
                                            ? "refacciones" + parameters
                                            : "refacciones"
                                        }
                                        title="Refacciones"
                                        id="Refacciones Header"
                                    /> */}
                                </NavDropdown>

                                <NavDropdown title="Nosotros" variant="dark" className={ weOptions.indexOf(selectedMenuItem) > -1 ? "text-primary selected" : "NavDropDownPadding"} >
                                    <NavDropItem
                                        style={{width: '100% !important'}}
                                        to={
                                            empresa == 'nissan'?
                                                parameters ? "/quienes-somos" + parameters : "/quienes-somos"
                                            :
                                                parameters ? "/quienes-somos" + parameters : "/quienes-somos"
                                        }
                                        title="¿Quiénes somos?"
                                        id="Quienes somos Header"
                                    />
                                    <NavDropItem
                                        to={
                                            empresa == 'nissan' ?
                                                parameters ? "/contacto" + parameters : "/contacto"
                                            : 
                                                parameters ? "/contacto" + parameters : "/contacto"
                                        }
                                        title="Horarios y Direcciones"
                                        id="Contacto Header"
                                    />
                                    <NavDropItem
                                        to={
                                            empresa == 'nissan' ?
                                                parameters ? "/rivero/blogs" + parameters : "/rivero/blogs"
                                            :
                                                parameters ? "/rivero/blogs" + parameters : "/rivero/blogs"
                                        }
                                        title="Blog"
                                        id="Blog Header"
                                    />
                                </NavDropdown>

                                <NavDropdown title="Promociones" variant="dark" className={promoOptions.indexOf(selectedMenuItem) > -1 ? "text-primary selected" : "NavDropDownPadding"}
                                    style={{ display: empresa === 'nissan' ? 'none' : empresa === 'principal' ? 'none' : 'block' }}
                                    >
                                    <NavDropItem
                                        to={
                                            empresa == 'nissan'?
                                            parameters
                                              ? "promociones-nissan/nuevos" + parameters
                                              : "promociones-nissan/nuevos"
                                              :
                                              parameters
                                                ? "promociones/nuevos" + parameters
                                                : "promociones/nuevos"
                                        }
                                        title="Carros Nuevos"
                                        id="Promociones Nuevos Header"
                                    />
                                    <NavDropItem
                                        to={
                                            empresa == 'nissan'?
                                            parameters
                                              ? "promociones-nissan/accesorios" + parameters
                                              : "promociones-nissan/accesorios"
                                              :
                                              parameters
                                                ? "promociones/accesorios" + parameters
                                                : "promociones/accesorios"
                                        }
                                        title="Accesorios"
                                        id="Promociones Accesorios Header"
                                    />
                                    <NavDropItem
                                        to={
                                            empresa == 'nissan'?
                                            parameters
                                              ? "promociones-nissan/taller-de-servicio" + parameters
                                              : "promociones-nissan/accesorios"
                                              :
                                              parameters
                                                ? "promociones/taller-de-servicio" + parameters
                                                : "promociones/taller-de-servicio"
                                        }
                                        title="Taller"
                                        id="Promociones Taller Servicio Header"
                                    />
                                </NavDropdown>

                                <div
                                    style={{
                                    height: "150px",
                                    width: "100%",
                                    top: 0,
                                    background: "rgba(255,255,255,0.3)",
                                    }}
                                    className="d-block d-sm-none"
                                >

                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className="d-md-block d-lg-none" hidden>
                                    <div style={{display: 'flex'}}>
                                            <a onClick={() => sendData('dd_whatsapp_8120010000')} href="https://wa.me/+5218120010000" target="_blank" rel="noreferrer" id="headerIconBoton" className="px-1">
                                                {/*<Image
                                                    fileName={contactaIcon.whatsapp}
                                                    alt="banner"
                                                    imgStyles={{position: 'absolute', objectFit: 'cover', width: '100%', borderRadius: 5}}
                                                    style={{width: '100px', height: 20}}
                                                />*/}
                                                WhatsApp: 81-2001-0000
                                            </a>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <a onClick={() => sendData('dd_tel_8188626262')} href="tel:8188626262" target="_blank" rel="noreferrer" id="headerIconBoton">
                                                    {/*<Image
                                                        fileName={contactaIcon.telefono}
                                                        alt="banner"
                                                        imgStyles={{position: 'absolute', objectFit: 'contain', width: '100%'}}
                                                        style={{width: '100%'}}
                                                    />*/}
                                                Teléfono: 81-88-62-62-62
                                            </a>
                                        </div>
                                    </div> 
                                </div>
                            </Nav>
                            <div className="d-lg-block d-none" hidden>
                                <Nav hidden>
                                    <div id="headerBarraDivision"></div>
                                    <div id="headerBotonesContactar" style={{display: 'flex', flexDirection: 'column'}}>
                                            <a onClick={() => sendData('dd_tel_8188626262')} href="tel:8188626262" target="_blank" rel="noreferrer" id="headerIconBoton">
                                                <Image
                                                    className="d-md-block d-none"
                                                    fileName={"boton_nissan_header_telefono"}
                                                    alt="banner"
                                                    imgStyles={{position: 'absolute', objectFit: 'contain'}}
                                                />
                                            </a>
                                            <a onClick={() => sendData('dd_whatsapp_8120010000')}href="https://wa.me/+5218120010000" target="_blank" rel="noreferrer" id="headerIconBoton">
                                                <Image
                                                    className="d-md-block d-none"
                                                    fileName={"boton_nissan_header_whatsapp"}
                                                    alt="banner"
                                                    imgStyles={{position: 'absolute'}}
                                                />
                                            </a>
                                    </div>
                                </Nav>
                            </div>
                        </Navbar>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <ModalCita show={cita} onHide={() => setCita(false)}/>
        </React.Fragment>
    )

} 