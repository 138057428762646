import React, { useState, useEffect } from "react";
import api from "../../actions/riveroapi";
import { Container, Row, Col } from "react-bootstrap";
import FormularioVentaNissan from "../../components/widgets/formularios/FormularioBasico";
import Image from "../../components/widgets/basico/image";

export default function AdWords({ slug, location }) {
  const [isLoading, setIsLoading] = useState(true);
  const [ad, setAd] = useState(null);
  const [model, setModel] = useState(null);
  const [year, setYear] = useState(null);
  const [promocion, setPromocion] = useState(null);
  const [como, setComo] = useState(null);
  const [evento, setEvento] = useState(slug);

  var origen = location.pathname.replaceAll("/", "-");

  origen = origen.substring(1, origen.lenght);

  const nombre = location.search.split("=");

  useEffect(() => {
    var comoSeEntero = location.pathname.split("/")[1];

    if (comoSeEntero === "publicidad") {
      setComo("MailMarketing");
    } else if (comoSeEntero === "adwords") {
      setComo("Adwords");
    } else {
      setComo("gruporivero.com");
    }

    api.getCampaign(slug).then((r) => {

        setAd({...r.data,
          image: r.data.image.replace('/adwords/', '/campaigns/')
        });
        setIsLoading(false);
        var firstModel = r.data.select.split("-")[0];
        firstModel = firstModel.split(" ");
        setModel(firstModel[0]);
        setYear(firstModel[firstModel.length - 2]);
        setPromocion(r.data.select.split("-")[1]);
      })
      .catch((error) => {
        console.log(`[Error, intente de nuevo ${error}`);
      });
  }, [slug, location.pathname]);

  if (isLoading) {
    return null;
  }

  function handleSubmit(e) {
    // Previene que el navegador recargue la página
    e.preventDefault();

    // Lee los datos del formulario
    const form = e.target;
    const formData = new FormData(form);
    console.log("Formulario",formData);

    // Puedes pasar formData como el cuerpo de la consulta directamente:
    /* fetch('/some-api', { method: form.method, body: formData }); */

    // O puedes trabajar con él como un objecto plano:
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson);

    api.postAsistenciaEvento(formJson).then((r) => {
      alert('Registro Enviado');
      window.location.replace('');
      })
      .catch((error) => {
        alert('Error de conexión. Intente más tarde.');
        console.log(`Error, intente de nuevo ${error}`);
      });
  }


  return (
    //EVENTO NISSAN KICK START

    <Container>
      <div style={{backgroundColor: 'black'}}>
        <Row className="m-0">
          <Image fileName={`topEventoKicks.png`} alt="KICKSTART" imgStyles={{ width: "100%" }} style={{ width: "100%" }}/>
        </Row>
        <Row style={{display:'flex', justifyContent: 'center'}}>
          <div className="py-4" style={{color: 'white', width: '90%',}}>
            <h3 class="detona">DETONA TU INSTINTO EN NISSAN RIVERO.</h3>
            <hr/>
            <h5 className="texto-evento">Ven a vivir una experiencia única y sé testigo<br/>
            de la revelación del nuevo <span className="m-0" style={{fontFamily: 'Arial', fontSize: '1em', color: '#b6cdf1'}}>Nissan Kicks 2025. </span><br/>
            Prepárate para una noche llena de sorpresas con<br/>
            comida, bebida, música y mucho más.
            </h5>
          </div>
          </Row>
          <Row className="m-0 pb-2" style={{backgroundColor: '#b00e1b'}}>
            <Col className="pt-4" xs={12} md={7} lg={7}>
              <div>
                <Image fileName={`downEventoKicks.png`} alt="KICKSTART" imgStyles={{ width: "100%"}} style={{ width: "100%"}}/>
              </div>
            </Col>
            <Col style={{paddingTop: '3em'}} xs={12} md={5} lg={5}>
              <div>
                <h3 className="p-0 m-0" style={{fontFamily: 'Narrow', color: 'white'}}>Confirma tu</h3><br/>
                <h3 className="p-0" style={{fontFamily: 'Narrow', color: 'white', marginTop: '-30px'}}>asistencia:</h3>
              </div>
              <div style={{color: 'white', backgroundColor: 'black', padding: '1em'}}>
              <form onSubmit={handleSubmit} method="POST" >

              <input hidden id="evento" maxlength="255" name="evento" size="20" type="text" value={evento}/>

                <div className="form-group pb-2">
                  <label style={{width: '70px'}} className="p-2" for="nombre">Nombre:</label><input className="p-2" placeholder="Nombre" id="nombre" maxlength="40" name="nombre" size="30" type="text" required />
                </div>

                <div className="form-group pb-2">
                    <label style={{width: '70px'}} className="p-2" for="telefono">Teléfono:</label><input className="p-2" placeholder="Telefono" id="telefono" maxlength="10" name="telefono" size="30" type="text" required />
                </div>
                  
                <div className="form-group pb-2">
                    <label style={{width: '70px'}} className="p-2" for="email">Email:</label><input className="p-2" placeholder="Correo" id="email" maxlength="80" name="email" size="30" type="text" required />
                </div>

                <div className="form-group pb-2">
                    <label className="p-2" htmlFor="name">Agencia: </label>
                    <select id="agencia" className="p-2" name="agencia" title="Agencia">
                      <option value="Contry">Contry</option>
                      <option value="Valle Oriente">Valle Oriente</option>
                      <option value="Valle">Valle</option>
                    </select>
                </div>

                <div className="form-group p-2 d-flex justify-content-center">
                    <input type="submit" className="btn bg-white" value="ENVIAR ASISTENCIA" />
                </div>
                </form>
              </div>
            </Col>
          </Row>
      </div>
    </Container>

  );
  }

/*     <Container className="pt-3">
      <h1 className="text-white">{ad ? ad.title : ""}</h1>
      <Row>
        <Col xs={12} md={6} lg={8}>
          {ad ? (
            <img src={ad.image}
              style={{ width: "100%" }} alt={ad.title} />
          ) : null}
        </Col>
        <Col xs={12} md={6} lg={4}> */
          {/* <FormularioVentaNissan
            pagina={`${origen ? origen + como : ""}`}
            titulo="Solicitar información"
            fullauto={"Chevrolet " + model + " " + year}
          >
            {ad.select ? (
              <CarSelect
                values={ad.select}
                setModelo={setModel}
                Anio={setYear}
                Promocion={setPromocion}
              />
            ) : null}
            <input
              type="hidden"
              id="Campaign_ID"
              name="Campaign_ID"
              value={nombre ? nombre[1] : ""}
              hidden
            />
            <input
              type="hidden"
              id="00Nf400000UBhZ6"
              name="00Nf400000UBhZ6"
              value={como}
            />
            <input
              type="hidden"
              id="00Nf400000UBhZt"
              name="00Nf400000UBhZt"
              value="Nuevos"
              hidden
            />

            <input
              type="hidden"
              id="00Nf400000UBhZ5"
              name="00Nf400000UBhZ5"
              value={origen ? origen + " " + promocion : ""}
            />
          </FormularioVentaNissan> */}
{/*         </Col>
      </Row>
    </Container> */}


function CarSelect(props) {
  const { values, setModelo, Anio, Promocion } = props;

  const selectList = values.split(";");
  const cantidad = selectList.length;
  const list = selectList.map((product) => (
    <option key={product}>{product}</option>
  ));
  function handleChange(product) {
    var valor = product.target.value;
    var modelos = valor.split(" - ")[0];
    var model = modelos.split(" ");
    console.log(modelos);
    setModelo(model[0]);
    Anio(model[model.length - 1]);
    Promocion(valor);
  }
  return (
    <div className="form-group" hidden={cantidad === 1}>
      <label htmlFor="adwords-input">Selecciona un carro</label>
      <select className="form-control" id="adwords-input" onChange={handleChange}>
        {list}
      </select>
    </div>
  );
}
